import { PaymentSchedule } from '../gateway/models';

export enum FeatureFlagKey {
  'WidgetTestFeature' = 'widget_test_feature',
  'ShowNewWidgetTriggerTerms' = 'show_new_widget_trigger_terms',
  'ForceBuyNowPayLaterTriggerTerm' = 'force_buy_now_pay_later_trigger_term',
  'CheckoutRemoveExcludedStatesDisclosures' = 'checkout_remove_excluded_states_disclosures',
  'PayIn8Widget' = 'pay_in_8_widget',
}

export type FeatureFlagsDictionary = {
  [key in FeatureFlagKey]?: {
    isEnabled: boolean
    [key: string]: string | boolean
  }
};

export enum FeatureFlagVariableKey {
  Text = 'text',
}

export enum ExperimentTextVariationKey {
  Original = '4-interest-free-payments',
  PayInInstallments = 'pay-in-installments',
  BuyNowPayLater = 'buy-now-pay-later',
  ShowTilaTerm = 'show-tila-term',
}

export interface EventModel {
  amount?: number
  widgetId: string
  url: string
  [key: string]: any
}

export const BankPartnerDefault = 'None';

export interface MerchantFeeTier {
  feeStartsAt: number
  totalFeePerOrder: number
}

export enum BankPartner {
  WebBank = 'WebBank',
  None = 'None',
}

export type MerchantFeeTierArray = MerchantFeeTier[];

export enum ScheduleType {
  'payin4',
  'payin8',
}

export type PaymentScheduleMap = Record<ScheduleType, Partial<PaymentSchedule>>;
